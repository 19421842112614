import { render, staticRenderFns } from "./AOI.vue?vue&type=template&id=51fd333a&scoped=true"
import script from "./AOI.vue?vue&type=script&lang=js"
export * from "./AOI.vue?vue&type=script&lang=js"
import style0 from "./AOI.vue?vue&type=style&index=0&id=51fd333a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51fd333a",
  null
  
)

export default component.exports